import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 75",
  week: "Semana 11",
  day: "27",
  month: "May",
  monthNumber: "05",
  date: "2020-05-27",
  path: "/cronologia/semana-11#dia-27-may/",
};

const Day75 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Primer día del periodo de luto oficial
        </ModDailyHighlight>
        <ModGraph name="crespon-luto" alt="Luto Oficial" />
        <ModText>
          Los territorios que están en Fase 2 podrán disfrutar desde hoy de
          algunas
          <InlineLink link="https://www.boe.es/boe/dias/2020/05/27/pdfs/BOE-A-2020-5323.pdf">
            medidas de flexibilización
          </InlineLink>{" "}
          para los desplazamientos de la población infantil y la práctica de
          actividad física.
        </ModText>
        <ModText>
          Mediante dos órdenes ministeriales, el Gobierno ha adoptado nuevas
          normas sobre la <strong>gestión de residuos</strong> en el proceso de
          desescalada. Estas órdenes, a propuesta del{" "}
          <strong>
            Ministerio para la Transición Ecológica y el Reto Demográfico
          </strong>
          , han sido publicadas entre el sábado y hoy en el Boletín Oficial del
          Estado.
        </ModText>
        <ModText>
          La{" "}
          <strong>
            Agencia Española de Medicamentos y Productos Sanitarios
          </strong>{" "}
          ha establecido una serie de requisitos para llevar a cabo el{" "}
          <strong>etiquetado e indicaciones de seguridad</strong> para el uso y
          conservación de geles y<strong>soluciones hidroalcohólicas</strong>.
        </ModText>
        <ModText>
          Los biocidas deben incluir en su etiquetado la clasificación "CLP" si
          alguno de sus ingredientes o una mezcla de ellos pueden ser
          clasificados como peligrosos y cuáles son las indicaciones y consejos
          que se deben tomar para evitar riesgos.
        </ModText>
        <ModText>
          El <strong>Ministerio de Cultura y Deporte</strong> ha coordinado la
          reapertura al público del{" "}
          <strong>
            Museo Nacional del Prado, el Museo Reina Sofía y el Museo Nacional
            Thyssen-Bornemisza
          </strong>
          . Estos museos volverán a abrir sus puertas el próximo 6 de junio.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2020-5265"
            name="Orden I- Gestion de residuos"
          />
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-5323"
            name="Orden II - Gestión de Residuos"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day75;
